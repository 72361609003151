<template>
  <div>
    <v-row class="no-favs-container">
      <v-col cols="10" offset="1">
        <div class="title-5">Oops!</div>
      </v-col>
    </v-row>
    <v-row class="text-center mt-0">
      <v-col cols="10" class="pt-2" offset="1">
        <div class="title-9">
          You don’t have any favourites yet.<br>
          Explore our chefs and feed and select your favourites.
        </div>
      </v-col>
    </v-row>
    <div class="main-btn-container">
      <v-btn
        depressed
        class="explore-dishes-btn"
        @click="$emit('go-to-main-feed')"
      >
        Explore Dishes
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NoFavourites'
  };
</script>

<style type="text/css">
  .no-favs-container {
    margin-top: 100px;
    text-align: center;
  }
</style>