<template>
  <v-container id="my-favourites">

    <TopBar
      @show-select-address="showSelectAddress = true"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <OurCooksFiltersBar
        :showChefList="showChefList"
        :showSelectFilters="showSelectFilters"
        @open-chef-list="openChefList"
        @open-filters-overlay="showSelectFilters = true"
      />

      <v-row v-if="myFavourites.length != 0">
        <DishItemTemplate
          v-for="(item, index) in myFavourites"
          :key="'favourite-'+index"
          :dishItem="item"
          @go-to-chef-profile="goToChefProfile"
          @toggleFavourite="toggleFavouriteAction(item)"
          @add-to-cart="showAddToCart = true"
        />
      </v-row>

      <div v-else>
        <NoFavourites
          @go-to-main-feed="goToMainFeed"
        />
      </div>
    </div>

    <SelectAddressOverlay
      :visible="showSelectAddress"
      @close="showSelectAddress = false"
    />

    <ChefList
      :visible="showChefList"
      @close="showChefList = false"
    />

    <SelectFiltersOverlay
      :visible="showSelectFilters"
      @close="showSelectFilters = false"
    />

    <AddToCartOverlay
      :visible="showAddToCart"
      @close="showAddToCart = false"
    />

    <SignInModal
      :visible="showSignInModal"
      @close="showSignInModal = false"
    />

    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
      @open-sign-in-modal="openSignInModal"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import TopBar from '@/components/Navigation/TopBar';
  import OurCooksFiltersBar from '@/components/MainFeed/OurCooksFiltersBar';
  import DishItemTemplate from '@/components/MainFeed/DishItemTemplate';
  import ChefList from '@/components/MainFeed/ChefList';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import AddToCartOverlay from '@/components/MainFeed/AddToCartOverlay';
  import SelectAddressOverlay from '@/components/MainFeed/SelectAddressOverlay';
  import SelectFiltersOverlay from '@/components/MainFeed/SelectFiltersOverlay';
  import NoFavourites from '@/components/MyFavourites/NoFavourites';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'MyFavourites',
    components: {
      TopBar,
      OurCooksFiltersBar,
      DishItemTemplate,
      ChefList,
      NavigationDrawer,
      AddToCartOverlay,
      SelectAddressOverlay,
      SelectFiltersOverlay,
      NoFavourites,
      SignInModal
    },
    computed: {
      ...mapGetters([
        "isUserLoggedIn",
        "myFavourites",
        "userProfile"
      ]),
    },
    data: () => ({
      showSignInModal: false,
      showSelectAddress: false,
      showChefList: false,
      showNavDrawer: false,
      showSelectFilters: false,
      showAddToCart: false,
      heart_white: "$vuetify.icons.heart_white",
      heart_blue: "$vuetify.icons.heart_blue",
      publicPath: process.env.BASE_URL
    }),
    methods: {
      ...mapActions([
        "toggleFavourite",
        "toggleFavouriteDish",
        "getChefDetails"
      ]),
      goToChefProfile(chefId) {
        this.getChefDetails(chefId).then(() => {
          this.$router.push({name: 'chef_profile'});
        });
      },
      openChefList() {
        this.showChefList = true;
      },
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      openSignInModal() {
        this.showNavDrawer = false;
        this.showSignInModal = true;
      },
      toggleFavouriteAction(dishItem) {
        let enabled = dishItem.favourite ? false : true;
        if (this.isUserLoggedIn) {
          let payload = {
            customerId: this.userProfile.id,
            data: {
              email: this.userProfile.email,
              itemIds: [dishItem.id],
              enabled: enabled
            }
          }
          this.toggleFavouriteDish(payload).then(() => {
            this.toggleFavourite({dishId: dishItem.id, enabled: enabled});
          });
        } else {
          this.toggleFavourite({dishId: dishItem.id, enabled: enabled});
        }
      }
    }
  };
</script>

<style type="text/css">
  #my-favourites {
    padding: 20px 20px 50px;
  }

  #my-favourites .my-page-content {
    margin-top: 70px;
  }

  .no-favs-container {
    margin-top: 100px;
    text-align: center;
  }
</style>